import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Stack } from "react-bootstrap";
import { dataPost } from "../../services/ApiEndPoint";
import { useAuth } from "../../contexts/AuthContext";
import { toast } from "react-hot-toast";
import { redirectByAdminRole, redirectByRole } from "../../utils/RedirectByrole";

import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  AlertTitle,
  Alert,
  Tooltip,
} from "@mui/material";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
  password: Yup.string()
    .required("This field is required")
    .min(8, "Pasword must be 8 or more characters")
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])\w+/,
      "Password ahould contain at least one uppercase and lowercase character"
    )
    .matches(/\d/, "Password should contain at least one number")
    .matches(
      /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
      "Password should contain at least one special character"
    ),
  rememberMe: Yup.boolean(),
});

const AdminLoginCard = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  //Show password
  const EndAdorment = ({ visible, setVisible }) => {
    return (
      <InputAdornment position="end">
        <IconButton
          onClick={() => {
            setVisible(!visible);
          }}
        >
          {visible ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
        </IconButton>
      </InputAdornment>
    );
  };

  // Initial form values
  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const { adminLogin } = useAuth();
  // Form submission handler
  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    console.log("Form values:", values);

    const { email, password } = values;
    try {
      const response = await dataPost("admin/login", { email, password });
      const data = await response.data;
      const { token, user } = data;
      //  localStorage.setItem("token", token);
      // localStorage.setItem("role", user.role);

      if (response.status === 200) {
        
        adminLogin(data.token, data.user);
        toast.success(data.message);
        <Navigate to={redirectByAdminRole(data.role)} />;
        resetForm();
      } else if (response.status == 401) {
        console.log("Invalid!", data.message);
        toast.custom(data.message);
      } else if (response.status == 404) {
        console.log("User Not Found", data.message);
        toast.custom(data.message);
      } else {
        console.log("Login Failed", data.message);
        toast.error("Login Failed", data.message);
      }
    } catch (error) {
      console.log("error", error.response.data.message);
      toast.error( error.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Card
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 3,
      }}
    >
       <Tooltip title="ATS INNOVATIONS" placement="right">
                <IconButton>
                  <img
                    src="https://salestechinc.com/salestech2.png"
                    alt="Company Logo"
                    style={{ width: 120, height: 60 }}
                  />
                </IconButton>
              </Tooltip>
      <CardContent>
        <Typography variant="h4" component="div" gutterBottom>
          Welcome Back !
        </Typography>
        <Typography variant="h5" component="div" gutterBottom>
          Login to your account
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched, onChange }) => (
            <Form>
              <Box sx={{ mt: 2 }}>
                <Field
                  as={TextField}
                  name="email"
                  type="email"
                  label="Email"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 2 }}
                  margin="normal"
                  helperText={
                    <>{errors.email && touched.email ? errors.email : ""}</>
                  }
                  error={Boolean(errors.email && touched.email)}
                />
                <Field
                  as={TextField}
                  fullWidth
                  name="password"
                  type={!visible ? "password" : "text"}
                  label="Password"
                  variant="outlined"
                  sx={{ mt: 2 }}
                  margin="normal"
                  helperText={
                    <>
                      {errors.password && touched.password
                        ? errors.password
                        : ""}
                    </>
                  }
                  error={Boolean(errors.password && touched.password)}
                  InputProps={{
                    endAdornment: (
                      <EndAdorment visible={visible} setVisible={setVisible} />
                    ),
                  }}
                />

             

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting}
                  sx={{
                    mt: "10px",
                    mr: "20px",
                    borderRadius: "10px",
                    color: "#ffffff",
                    minWidth: "170px",
                    minHeight: "50px",
                    backgroundColor: "#000080",
                  }}
                >
                  Login
                </Button>

              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default AdminLoginCard;
