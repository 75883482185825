// import axios from "axios";
// import toast from "react-hot-toast";



// // Define the base URLs
// const baseURL = "http://localhost:5000/api";
// const baseURL1 = "https://atst-api.up.railway.app/api";


// // Create an Axios instance with the base URL and default headers
// const instance = axios.create({
//   baseURL: baseURL1,

//   headers: {
//     "Content-Type": "application/json",
//   },
//   withCredentials: true,
// });

// // Create an Axios instance for multipart form data
// const multipartInstance = axios.create({
//   baseURL: baseURL1,
//   headers: {
//     "Content-Type": "multipart/form-data",
//   },
//   withCredentials: true,
// });



// // Function for making GET requests with token authorization
// export const dataFetch = (url, token) =>
//   instance.get(url, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });

// // Function for making POST requests
// export const dataPost = (url, data, token) =>
//   instance.post(url, data, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });

//   // Function for making POST requests with multipart/form-data
// export const dataPost1 = (url, data, token) =>
//   multipartInstance.post(url, data, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });

// // Function for making PUT requests
// export const dataUpdate = (url, data, token) =>
//   instance.put(url, data, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });

// // Function for making DELETE requests
// export const dataDelete = (url, token) =>
//   instance.delete(url, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });

// // Add a request interceptor
// instance.interceptors.request.use(
//   function (config) {
//     // Modify the request before sending it, if necessary
//     return config;
//   },
//   function (error) {
//     console.log("Interceptor Error", error);
//     // Handle request error
//     return Promise.reject(error);
//   }
// );

// // Add a response interceptor
// instance.interceptors.response.use(
//   function (response) {
//     console.log("Interceptor Response", response);
//     return response;
//   },
//   function (error) {
//     console.log("Interceptor Error", error);
//     return Promise.reject(error);
//   }
// );


// // Add a request interceptor for multipart instance
// multipartInstance.interceptors.request.use(
//   function (config) {
//     // Modify the request before sending it, if necessary
//     return config;
//   },
//   function (error) {
//     console.log("Multipart Interceptor Error", error);
//     // Handle request error
//     return Promise.reject(error);
//   }
// );

// // Add a response interceptor for multipart instance
// multipartInstance.interceptors.response.use(
//   function (response) {
//     console.log("Multipart Interceptor Response", response);
//     return response;
//   },
//   function (error) {
//     console.log("Multipart Interceptor Error", error);
//     return Promise.reject(error);
//   }
// );


import axios from "axios";

// Define the base URLs
const baseURL = "http://localhost:5000/api";
const baseURL1 = "https://crmserver-production-fb14.up.railway.app/api";


// Create an Axios instance with the base URL and default headers
const instance = axios.create({
  baseURL: baseURL1,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// Create an Axios instance for multipart form data
const multipartInstance = axios.create({
  baseURL: baseURL1,
  headers: {
    "Content-Type": "multipart/form-data",
  },
  withCredentials: true,
});

// Helper function to check if the base URL is local
const isLocalBaseURL = (url) => url.startsWith("http://localhost:5000");

// Function for making GET requests with token authorization
export const dataFetch = (url, token) =>
  instance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Function for making POST requests
export const dataPost = (url, data, token) =>
  instance.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Function for making POST requests with multipart/form-data
export const dataPost1 = (url, data, token) =>
  multipartInstance.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Function for making PUT requests
export const dataUpdate = (url, data, token) =>
  instance.put(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Function for making DELETE requests
export const dataDelete = (url, token) =>
  instance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    if (isLocalBaseURL(config.baseURL)) {
      console.log("Request Interceptor Config:", config);
    }
    return config;
  },
  function (error) {
    if (isLocalBaseURL(error.config.baseURL)) {
      console.log("Interceptor Request Error:", error);
    }
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    if (isLocalBaseURL(response.config.baseURL)) {
      console.log("Response Interceptor Response:", response);
    }
    return response;
  },
  function (error) {
    if (isLocalBaseURL(error.config.baseURL)) {
      console.log("Interceptor Response Error:", error);
    }
    return Promise.reject(error);
  }
);

// Add a request interceptor for multipart instance
multipartInstance.interceptors.request.use(
  function (config) {
    if (isLocalBaseURL(config.baseURL)) {
      console.log("Multipart Request Interceptor Config:", config);
    }
    return config;
  },
  function (error) {
    if (isLocalBaseURL(error.config.baseURL)) {
      console.log("Multipart Interceptor Request Error:", error);
    }
    return Promise.reject(error);
  }
);

// Add a response interceptor for multipart instance
multipartInstance.interceptors.response.use(
  function (response) {
    if (isLocalBaseURL(response.config.baseURL)) {
      console.log("Multipart Response Interceptor Response:", response);
    }
    return response;
  },
  function (error) {
    if (isLocalBaseURL(error.config.baseURL)) {
      console.log("Multipart Interceptor Response Error:", error);
    }
    return Promise.reject(error);
  }
);
