import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  Button,
  TableSortLabel,
  IconButton,
  Box,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { dataFetch, dataPost, dataUpdate } from "../../../../services/ApiEndPoint";
import { useAuth } from "../../../../contexts/AuthContext";
import { getDateTimeSync } from "../../../../utils/DateTimeHelper";
import toast from "react-hot-toast";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../../utils/theme";

const MgrLeadsTable = ({ userId }) => {
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [users, setUsers] = useState([]);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [deleteLeads, setDeleteLeads] = useState([]);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const { token } = useAuth();

 // Fetching users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token);
        const allUsers = Array.isArray(response.data.data) ? response.data.data : [];
        // Filter users by role 'user'
        const filteredUsers = allUsers.filter(user => user.role === 'user');
        setUsers(filteredUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, [token]);

  // Fetching leads
  const fetchLeads = async () => {
    setLoadingData(true);
    try {
      const skip = (page - 1) * rowsPerPage;
      const url = `lead/myleads/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}&assignedLeadId=${userId}&leadOwner=${userId}`;
      const response = await dataFetch(url, token);
console.log(response)
      if (response.status === 200) {
        setLeads(response.data.data);
        setTotalLeads(response.data.total);
      } else {
        console.error(`Error fetching leads: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  // Handling selection of leads
  const handleSelectLead = (leadId) => {
    setSelectedLeads((prevSelected) => {
      if (prevSelected.includes(leadId)) {
        return prevSelected.filter((id) => id !== leadId);
      } else {
        return [...prevSelected, leadId];
      }
    });
  };

  const handleSelectAllLeads = (event) => {
    if (event.target.checked) {
      setSelectedLeads(leads.map((lead) => lead._id));
    } else {
      setSelectedLeads([]);
    }
  };

  // Handle Delete Leads action
  const handleDeleteLeads = () => {
    if (selectedLeads.length === 0) {
      toast.error("Please select at least one lead to delete.");
      return;
    }
    setDeleteLeads(selectedLeads); // Set the leads to be deleted
    setIsDeleteConfirmationOpen(true); // Open the delete confirmation dialog
  };

  // Confirm Deletion
  const confirmDeleteLeads = async () => {
    try {
      const response = await dataPost("/lead/many", { leadIds: deleteLeads }, token);

      if (response.data.success) {
        toast.success("Selected leads deleted successfully!");
        setSelectedLeads([]); // Reset selected leads
        setIsDeleteConfirmationOpen(false); // Close the delete confirmation modal
        fetchLeads(); // Refresh leads
      } else {
        toast.error(response.data.message || "Failed to delete leads.");
      }
    } catch (error) {
      console.error("Error deleting leads:", error);
      toast.error("Error deleting leads");
    }
  };

  // Transfer lead logic
  const handleTransferLead = async () => {
    if (!selectedLeads.length || !selectedUser) {
      toast.error("Please select at least one lead and a user to transfer.");
      return;
    }
  
    try {
      for (const leadId of selectedLeads) {
        const response = await dataPost(
          "/lead/transfer",
          { leadId, currentAssignedUserId: userId, newAssignedUserId: selectedUser },
          token
        );
  
        if (response.status !== 200 || !response.data.success) {
          throw new Error(response.data.message || `Failed to transfer lead with ID: ${leadId}`);
        }
      }
  
      toast.success("Leads transferred successfully!");
      fetchLeads();
      setSelectedLeads([]);
      setIsTransferModalOpen(false);
    } catch (error) {
      console.error("Error transferring leads:", error);
      toast.error(error.response?.data?.message || "Error transferring leads");
    }
  };
  
  
  

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalLeads / rowsPerPage);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h5" gutterBottom>
          Lead Dashboard - Page {page}
        </Typography>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsTransferModalOpen(true)}
            disabled={selectedLeads.length === 0}
            sx={{ backgroundColor: "blue", color: "white", "&:hover": { backgroundColor: "darkblue" }, borderRadius: "8px" }}
          >
            Transfer Lead
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteLeads}
            disabled={selectedLeads.length === 0}
            sx={{ backgroundColor: "red", color: "white", "&:hover": { backgroundColor: "darkred" }, borderRadius: "8px" }}
          >
            Delete Lead
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12}>
        {loadingData ? (
          <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "50vh" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox checked={selectedLeads.length === leads.length} onChange={handleSelectAllLeads} inputProps={{ "aria-label": "select all leads" }} />
                  </TableCell>
                  {["name", "email", "date", "phonenumber"].map((header) => (
                    <TableCell key={header} sx={{ fontWeight: "bold", textAlign: "center", backgroundColor: colors.primary[500], color: "white" }}>
                      <TableSortLabel
                        active={sortField === header}
                        direction={sortField === header ? sortOrder : "asc"}
                        onClick={() => handleSortRequest(header)}
                      >
                        {header.charAt(0).toUpperCase() + header.slice(1)}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {leads.map((lead) => (
                  <TableRow key={lead._id}>
                    <TableCell padding="checkbox">
                      <Checkbox checked={selectedLeads.includes(lead._id)} onChange={() => handleSelectLead(lead._id)} />
                    </TableCell>
                    <TableCell>{lead.firstname}</TableCell>
                    <TableCell>{lead.email}</TableCell>
                    <TableCell>{getDateTimeSync(lead?.date)}</TableCell>
                    <TableCell>{lead.phonenumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
          <Typography sx={{ ml: 2 }}><strong>Total Pages: {totalPages}</strong></Typography>
        </Box>
      </Grid>

      {/* Transfer Lead Modal */}
      <Dialog open={isTransferModalOpen} onClose={() => setIsTransferModalOpen(false)} aria-labelledby="transfer-lead-dialog-title">
        <DialogTitle id="transfer-lead-dialog-title">Transfer Lead</DialogTitle>
        <DialogContent>
          <Typography>Select a user to transfer the lead(s) to:</Typography>
          <Box sx={{ maxHeight: 400, overflowY: "auto", marginTop: "16px" }}>
            {users.map((user) => (
              <Button
                key={user._id}
                variant="outlined"
                sx={{ display: "block", width: "100%", marginBottom: "8px" }}
                onClick={() => setSelectedUser(user._id)}
                disabled={selectedUser === user._id}
              >
                {user.name}
              </Button>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsTransferModalOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleTransferLead} color="secondary" variant="contained">Transfer</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Modal */}
      <Dialog open={isDeleteConfirmationOpen} onClose={() => setIsDeleteConfirmationOpen(false)} aria-labelledby="delete-lead-dialog-title">
        <DialogTitle id="delete-lead-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete the selected lead(s)?</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteConfirmationOpen(false)} color="primary">Cancel</Button>
          <Button onClick={confirmDeleteLeads} color="secondary" variant="contained">Confirm Delete</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default MgrLeadsTable;



